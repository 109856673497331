<template>
  <Teleport to="body">
    <div class="fixed bottom-1 right-16 z-[9999] w-full max-w-sm">
      <!-- Header/Toggle Button -->
      <button
        class="flex w-full items-center justify-between rounded-t-lg bg-gradient-to-r from-orange-100 to-orange-200 px-2 py-1 text-left shadow-lg transition-all duration-200 hover:from-orange-200 hover:to-orange-300 focus:outline-none"
        :class="{
          'rounded-b-lg shadow-md': !isExpanded,
          'shadow-xl': isExpanded,
        }"
        aria-label="Toggle Mocked API Requests list"
        @click="toggleExpand"
      >
        <div class="flex items-center space-x-3">
          <div class="relative">
            <span
              v-if="hasMockedRequests && recentActivity"
              class="absolute inset-0 animate-ping rounded-full bg-orange-400 opacity-75"
            />
            <span
              class="relative block size-2 rounded-full border border-orange-600/20"
              :class="indicatorClass"
            />
          </div>
          <span class="text-grey-900 text-xs font-medium">
            Mocked API Requests
            <span
              class="ml-1 rounded-full bg-yellow-600/20 px-1.5 py-0.5 text-xs font-semibold"
            >
              {{ mockedRequests.length }}
            </span>
          </span>
        </div>

        <svg
          class="size-5 text-yellow-800/70 transition-transform duration-300 ease-in-out"
          :class="{ 'rotate-180': !isExpanded }"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>

      <!-- List of Requests -->
      <Transition
        enter-active-class="transition ease-out duration-300"
        enter-from-class="transform opacity-0 scale-95 -translate-y-2"
        enter-to-class="transform opacity-100 scale-100 translate-y-0"
        leave-active-class="transition ease-in duration-200"
        leave-from-class="transform opacity-100 scale-100 translate-y-0"
        leave-to-class="transform opacity-0 scale-95 -translate-y-2"
      >
        <div
          v-show="isExpanded"
          class="border-grey-200 overflow-hidden rounded-b-lg border border-t-0 bg-white shadow-xl"
        >
          <div v-if="hasMockedRequests" class="max-h-72 overflow-y-auto">
            <ul class="text-xs">
              <li
                v-for="req in mockedRequests"
                :key="req.id"
                class="border-grey-100 group flex items-center justify-between border-t px-4 py-2.5 transition-colors duration-150 first:border-t-0 hover:bg-yellow-50"
              >
                <div class="flex items-center space-x-3 overflow-hidden">
                  <span
                    class="text-xxs flex h-6 items-center justify-center rounded px-2 font-mono font-bold tracking-wide shadow-sm"
                    :class="getMethodClass(req.method)"
                  >
                    {{ req.method }}
                  </span>
                  <span
                    class="text-grey-700 group-hover:text-grey-900 truncate font-medium"
                    :title="req.url"
                  >
                    {{ req.url }}
                  </span>
                </div>
                <span class="text-grey-500 shrink-0 pl-2 font-mono">
                  {{ req.timeString }}
                </span>
              </li>
            </ul>
          </div>
          <div
            v-else
            class="flex flex-col items-center justify-center px-4 py-8 text-center"
          >
            <svg
              class="text-grey-300 mb-2 size-10"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
              />
            </svg>
            <p class="text-grey-500 text-sm">
              No mocked requests captured yet.
            </p>
            <p class="text-grey-400 mt-1 text-xs">
              API requests will appear here when intercepted by MSW
            </p>
          </div>

          <div
            class="border-grey-100 bg-grey-50 text-grey-500 text-xxs flex items-center justify-between border-t px-4 py-2"
          >
            <span>Mock Service Worker (MSW) Active</span>
            <button
              v-if="hasMockedRequests"
              class="text-grey-600 hover:bg-grey-200 hover:text-grey-800 rounded px-2 py-1 text-xs font-medium"
              @click="clearRequests"
            >
              Clear All
            </button>
          </div>
        </div>
      </Transition>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import dayjs from "dayjs";
import { computed, onMounted, onUnmounted, ref } from "vue";

import { worker } from "./browser";

interface MockedRequestInfo {
  id: string;
  method: string;
  url: string;
  timestamp: number;
  timeString: string;
}

const MAX_REQUESTS = 50;
const mockedRequests = ref<MockedRequestInfo[]>([]);
const isExpanded = ref(false);
const recentActivity = ref(false); // For the pulse animation

function formatTimeStamp(date: Date) {
  return dayjs(date).format("HH:mm:ss");
}

function handleRequestMatch(args: { request: Request; requestId: string }) {
  const today = new Date();
  const newRequest: MockedRequestInfo = {
    id: args.requestId,
    method: args.request.method,
    url: new URL(args.request.url).pathname + new URL(args.request.url).search,
    timestamp: today.getTime(),
    timeString: formatTimeStamp(today),
  };

  mockedRequests.value.push(newRequest);

  if (mockedRequests.value.length > MAX_REQUESTS) {
    mockedRequests.value.splice(MAX_REQUESTS);
  }

  recentActivity.value = true;
  setTimeout(() => {
    recentActivity.value = false;
  }, 2000);
}

onMounted(() => {
  try {
    worker.events.on("request:match", handleRequestMatch);
  } catch (error) {
    console.error(
      "[MockDisplay] Failed to subscribe to MSW events. Is MSW running?",
      error
    );
  }
});

onUnmounted(() => {
  try {
    worker.events.removeListener("request:match", handleRequestMatch);
  } catch (error) {
    console.error(
      "[MockDisplay] Failed to unsubscribe from MSW events.",
      error
    );
  }
});

function toggleExpand() {
  isExpanded.value = !isExpanded.value;
}

function clearRequests() {
  mockedRequests.value = [];
}

function getMethodClass(method: string): string {
  switch (method) {
    case "POST":
      return "bg-green-100 text-green-700 border border-green-200";
    case "PUT":
    case "PATCH":
      return "bg-orange-100 text-orange-700 border border-orange-200";
    case "DELETE":
      return "bg-red-100 text-red-700 border border-red-200";
    case "GET":
    default:
      return "bg-blue-100 text-blue-700 border border-blue-200";
  }
}

const hasMockedRequests = computed(() => mockedRequests.value.length > 0);
const indicatorClass = computed(() =>
  hasMockedRequests.value
    ? "bg-orange-500 shadow-sm shadow-orange-500/50"
    : "bg-grey-400"
);
</script>
