<template>
  <VueQueryDevtools />
  <div class="kinn-app text-app-black">
    <transition name="fade">
      <DialogsRoot>
        <v-app>
          <template v-if="!loading && !isLoading && !setupStore.isLoading">
            <BlankLayout v-if="isBlankLayout" />
            <DefaultLayout v-else-if="isAuthenticated" />
            <AppSPASkeleton v-else-if="isLoggingOut" />
            <BlankLayout v-else />
          </template>
          <template v-else>
            <AppSPASkeleton />
          </template>
          <TeleportTarget :name="teleportTargets.body" />
          <AppSnackbar />
          <MockedRequestsDisplay v-if="isMockingEnabled" />
        </v-app>
      </DialogsRoot>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { VueQueryDevtools } from "@tanstack/vue-query-devtools";
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import { useIntercom } from "@/composables/use-intercom";
import { useVueQueryCleanup } from "@/composables/use-vue-query-cleanup";
import { useAuthStore } from "@/store/auth-store";

import AppSPASkeleton from "./components/skeletons/AppSPASkeleton.vue";
import { useRemoteTranslations } from "./composables/use-remote-translations";
import BlankLayout from "./layouts/BlankLayout.vue";
import DefaultLayout from "./layouts/DefaultLayout.vue";
import AppSnackbar from "./modules/snackbar/components/AppSnackbar.vue";
import TeleportTarget from "./modules/teleports/components/TeleportTarget.vue";
import { teleportTargets } from "./modules/teleports/teleports.config";
import MockedRequestsDisplay from "./msw/MockedRequestsDisplay.vue";
import DialogsRoot from "./plugins/dialogs/DialogsRoot.vue";
import { usePermissionStore } from "./store/permission-store";
import { useSetupStore } from "./store/setup-store";
import { useUserSettingsStore } from "./store/user-settings-store";

// keep this initialization here!
useUserSettingsStore();

const router = useRouter();
const route = useRoute();
const permissionStore = usePermissionStore();
const setupStore = useSetupStore();
const authStore = useAuthStore();
const { isLoading } = useRemoteTranslations();
useVueQueryCleanup();

const loading = ref(true);
const isAuthenticated = computed(() => {
  return authStore.isAuthenticated && permissionStore.permissions;
});
const isLoggingOut = computed(() => {
  return authStore.isLoggingOut;
});

const layout = computed(() => route.meta?.layout);
const isBlankLayout = computed(() => layout.value === "blank");

const isMockingEnabled = computed(() => {
  return import.meta.env.VITE_ENABLE_MSW === "true" || import.meta.env.DEV;
});

useIntercom();

onMounted(() => {
  authStore
    .initializeSession()
    .catch(() => {
      authStore.logout();
    })
    .finally(() => {
      loading.value = false;
    });
});

router.onError((error, to) => {
  const errors = [
    "Failed to fetch dynamically imported module",
    "Importing a module script failed",
  ];
  if (errors.some((e) => error.message.includes(e))) {
    window.location.href = to.fullPath;
  }
});
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
