// global styles
// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import "unfonts.css";
import "./scss/style.scss";
import "./scss/style-new.scss";
// This order is important so tailwind doesnt override the datepicker styles
import "@vuepic/vue-datepicker/dist/main.css";
import "./scss/custom-date-picker.scss";

import { createApp } from "vue";

import { i18nInit } from "@/plugins/i18n-plugin";
import { routerInit } from "@/plugins/router-plugin";
import { piniaInit } from "@/plugins/use-pinia-plugin";
import { vueMotion } from "@/plugins/vue-motion-plugin";
import { vuetifyInit } from "@/plugins/vuetify-plugin";
import { logHiringMessage } from "@/utils/devtools-utils";

import App from "./App.vue";
import { enableMocking } from "./msw/browser";
import { installPlugins } from "./plugins";
import { setDayjsLocale } from "./plugins/dayjs";
import { setupSentry } from "./plugins/sentry-plugin";
import { vueUseHeadInit } from "./plugins/use-head-plugin";
import { vueNotificationInit } from "./plugins/vue-notification-plugin";
import { vueQueryInit } from "./plugins/vue-query-plugin";
import router from "./router";

if (window !== window.parent) {
  const message =
    "Embedding the Kinnovis manager via iFrame is not supported. Reach out to our support team in case you have any questions.";
  document.getElementsByTagName("body")[0].innerHTML =
    `<h2 class="text-2xl text-center mt-4">${message}</h2>`;
  throw new Error(message);
}

const app = createApp(App);

logHiringMessage();
setDayjsLocale("en");

installPlugins(
  app,
  routerInit,
  i18nInit,
  piniaInit,
  vuetifyInit,
  vueQueryInit,
  vueNotificationInit,
  vueUseHeadInit,
  vueMotion
);

setupSentry(app, router);
enableMocking();
app.mount("#app");
