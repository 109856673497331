import { ApiService } from "@/api/base-proxy";
import type {
  IConversationMessageRatingCreateDto,
  IConversationMessageRatingResponseDto,
  IConversationResponseDto,
  IConversationsFilterParametersDto,
  IConversationsResponseDto,
} from "@/types/jane-gpt-types";

/**
 * ONLY FOR DEVELOPMENT! This will return true if the env var contains all needed settings to work with the jane API directly.
 * Otherwise, all requests will be routed through the BE for security reasons.
 * @private
 */
export function useJaneEndpoint() {
  const { url, apiKey } = getJaneConfig();
  return import.meta.env.VITE_APP_ENV === "development" && !!url && !!apiKey;
}

/**
 * ONLY FOR DEVELOPMENT! This will return local development settings to temporarily route the requests directly to jane and not through the BE.
 * @private
 */
function getJaneConfig() {
  return {
    url: import.meta.env.VITE_APP_JANE_ENDPOINT,
    apiKey: import.meta.env.VITE_APP_JANE_API_KEY,
    headers: {
      "x-api-key": import.meta.env.VITE_APP_JANE_API_KEY,
    },
  };
}

class ChatLogsService extends ApiService {
  constructor() {
    if (useJaneEndpoint()) {
      super(getJaneConfig().url);
      this.basePath = getJaneConfig().url + "/conversations";
    } else {
      super("jane-api/proxy/conversations");
    }
  }

  getHeaders() {
    if (useJaneEndpoint()) {
      return getJaneConfig().headers;
    }

    return {};
  }

  getConversations(
    params: IConversationsFilterParametersDto
  ): Promise<IConversationsResponseDto> {
    return this.httpClient.get(this.basePath, {
      params,
      headers: this.getHeaders(),
    });
  }

  getConversation(id: number | string): Promise<IConversationResponseDto> {
    return this.httpClient.get(`${this.basePath}/${id}`, {
      headers: this.getHeaders(),
    });
  }

  rateChatMessage(
    sessionId: number | string,
    messageId: number | string,
    body: IConversationMessageRatingCreateDto
  ): Promise<IConversationMessageRatingResponseDto> {
    return this.httpClient.post(
      `${this.basePath}/${sessionId}/messages/${messageId}/rating`,
      body,
      { headers: this.getHeaders() }
    );
  }

  deleteChatMessageRating(
    sessionId: number | string,
    messageId: number | string
  ): Promise<IConversationMessageRatingResponseDto> {
    return this.httpClient.delete(
      `${this.basePath}/${sessionId}/messages/${messageId}/rating`,
      { headers: this.getHeaders() }
    );
  }
}

export const chatLogsService = new ChatLogsService();
