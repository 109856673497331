<template>
  <v-tooltip
    :location="tooltipLocation"
    :disabled="disabled"
    :max-width="maxWidth"
    content-class="text-primary border tooltip-styling"
  >
    <template #activator="data">
      <slot name="activator" v-bind="data" />
    </template>

    <div
      class="whitespace-pre-wrap text-black"
      :class="{
        'text-center': position === 'top' || position === 'bottom',
        'text-right': position === 'left',
      }"
    >
      <slot />
    </div>
  </v-tooltip>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import type { ITooltipProps } from "@/modules/ui/tooltip/tooltip.types.ts";

const props = withDefaults(defineProps<ITooltipProps>(), {
  disabled: false,
  position: "top",
  maxWidth: undefined,
});

const tooltipLocation = computed(() => {
  if (props.position === "left") return "start";
  if (props.position === "right") return "end";
  return props.position;
});
</script>

<style lang="scss">
.hover:hover {
  cursor: help;
}

.tooltip-styling {
  border: 0.5px solid rgba(10, 69, 231, 0.15) !important;
  box-shadow: rgb(0 0 0 / 10%) 0 4px 12px 0 !important;
  background-color: rgba(250, 251, 255, 1) !important;
}
</style>
