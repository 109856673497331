import { http, HttpResponse } from "msw";

import { getApiBasePath } from "@/utils/request-utils";

import {
  createPaginatedEntities,
  createPaginatedResponse,
  createResponse,
} from "./mock-generators";

const baseUrl = import.meta.env.VITE_APP_API_URL;
const apiPath = (path: string) => `${baseUrl}${getApiBasePath()}${path}`;

/**
 * MSW HANDLER EXAMPLES
 *
 * This file contains examples of how to create different types of mock handlers.
 * Uncomment and modify the examples below when you need to add mock handlers.
 *
 * ----- BASIC CRUD OPERATIONS -----
 *
 * // GET request returning a paginated response
 * http.get(apiPath("/users"), () => {
 *   return HttpResponse.json(
 *     createPaginatedResponse([
 *       {
 *         id: "1",
 *         firstName: "Mock",
 *         lastName: "User",
 *         email: "mock.user@example.com",
 *       },
 *     ])
 *   );
 * }),
 *
 * // POST request creating a resource
 * http.post(apiPath("/users"), () => {
 *   return HttpResponse.json(
 *     createResponse({
 *       id: "1",
 *       firstName: "Mock",
 *       lastName: "User",
 *       email: "mock.user@example.com",
 *     })
 *   );
 * }),
 *
 * // PUT request updating a resource
 * http.put(apiPath("/users"), () => {
 *   return HttpResponse.json(
 *     createResponse({
 *       id: "1",
 *       firstName: "Mock",
 *       lastName: "User",
 *       email: "mock.user@example.com",
 *     })
 *   );
 * }),
 *
 * // DELETE request
 * http.delete(apiPath("/users"), () => {
 *   return HttpResponse.json(
 *     createResponse({
 *       id: "1",
 *       firstName: "Mock",
 *       lastName: "User",
 *       email: "mock.user@example.com",
 *     })
 *   );
 * }),
 *
 * // PATCH request for partial updates
 * http.patch(apiPath("/users"), () => {
 *   return HttpResponse.json(
 *     createResponse({
 *       id: "1",
 *       firstName: "Mock",
 *       lastName: "User",
 *       email: "mock.user@example.com",
 *     })
 *   );
 * }),
 *
 * ----- ARRAY RESPONSES -----
 *
 * // GET request returning an array
 * http.get(apiPath("/country"), () => {
 *   return HttpResponse.json(
 *     createResponse([
 *       {
 *         id: "AF",
 *         name: "Mars",
 *         phone_prefix: "+93",
 *         country_flag_url: "https://example.com/flags/af.svg",
 *         translation_key: "Mars",
 *         translated_name: "Mars",
 *       },
 *       // Add more items as needed
 *     ])
 *   );
 * }),
 *
 * ----- PAGINATION EXAMPLES -----
 *
 * // Example with URL parameters for pagination
 * http.get(apiPath("/items"), ({ request }) => {
 *   const url = new URL(request.url);
 *   const page = parseInt(url.searchParams.get("page") || "1");
 *   const page_size = parseInt(url.searchParams.get("page_size") || "10");
 *
 *   return HttpResponse.json(
 *     createPaginatedEntities({
 *       page,
 *       page_size,
 *       total: 50,
 *       nameFn: (i) => `Item ${i + 1}`,
 *     })
 *   );
 * }),
 *
 * // Pagination with static data
 * http.get(apiPath("/special-items"), ({ request }) => {
 *   const url = new URL(request.url);
 *   const page = parseInt(url.searchParams.get("page") || "1");
 *   const page_size = parseInt(url.searchParams.get("page_size") || "10");
 *
 *   return HttpResponse.json(
 *     createPaginatedEntities({
 *       page,
 *       page_size,
 *       data: [
 *         {
 *           id: "1",
 *           name: "Item 1",
 *           description: "Description 1",
 *         },
 *         {
 *           id: "2",
 *           name: "Item 2",
 *           description: "Description 2",
 *         },
 *       ],
 *     })
 *   );
 * }),
 *
 * // Pagination with dynamic data using dataFn
 * http.get(apiPath("/function-items"), ({ request }) => {
 *   const url = new URL(request.url);
 *   const page = parseInt(url.searchParams.get("page") || "1");
 *   const page_size = parseInt(url.searchParams.get("page_size") || "10");
 *
 *   return HttpResponse.json(
 *     createPaginatedEntities({
 *       page,
 *       page_size,
 *       total: 50,
 *       dataFn: (i) => ({
 *         id: i + 1,
 *         name: `Item ${i + 1}`,
 *         description: `Description ${i + 1}`,
 *         is_active: i % 2 === 0,
 *         color: Math.random() > 0.5 ? "red" : "blue",
 *       }),
 *     })
 *   );
 * }),
 *
 * ----- ROUTE PARAMETERS -----
 *
 * // GET request with route parameter
 * http.get(apiPath("/user/:id"), ({ params }) => {
 *   const id = params.id;
 *   const idNum = parseInt(id as string);
 *
 *   return HttpResponse.json(
 *     createResponse({
 *       id: idNum,
 *       email: `user${idNum}@example.com`,
 *       firstname: `First ${idNum}`,
 *       lastname: `Last ${idNum}`,
 *       // Additional fields as needed
 *     })
 *   );
 * }),
 */

// Add your actual handlers here when needed
export const handlers = [];
