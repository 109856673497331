import {
  useMutation,
  type UseMutationOptions,
  type UseMutationReturnType,
} from "@tanstack/vue-query";
import { toValue } from "vue";

import type { IApiError, ISimpleAxiosError } from "@/api/types/api-error-types";
import type { IResponse } from "@/api/types/general-types";
import { useNotifications } from "@/modules/snackbar/composables/use-notifications";

type IUseCustomMutationOptions<TResponse, TPayload> = UseMutationOptions<
  TResponse extends void ? void : IResponse<TResponse>,
  ISimpleAxiosError<IApiError>,
  TPayload
>;

export function useCustomMutation<TResponse, TPayload = undefined>(
  options: IUseCustomMutationOptions<TResponse, TPayload>
): UseMutationReturnType<
  TResponse extends void ? void : IResponse<TResponse>,
  ISimpleAxiosError<IApiError>,
  TPayload,
  unknown
> {
  const { showErrorResponse } = useNotifications();

  let shouldShowDefaultError = true;

  const customOptions: IUseCustomMutationOptions<TResponse, TPayload> = {
    ...options,
    onError: (error, variables, context) => {
      // Type assertion needed to access onError from options
      // MaybeRefDeep makes type inference a bit tricky here :D
      const opt = toValue(options) as {
        onError: (
          error: ISimpleAxiosError<IApiError>,
          variables: TPayload,
          context: unknown
        ) => void;
      };
      if (opt.onError) {
        shouldShowDefaultError = false;
        opt.onError(error, variables, context);
      }

      if (shouldShowDefaultError) {
        showErrorResponse(error);
      }
    },
  };

  const mutation = useMutation<
    TResponse extends void ? void : IResponse<TResponse>,
    ISimpleAxiosError<IApiError>,
    TPayload
  >(customOptions);

  const originalMutate = mutation.mutate;
  const originalMutateAsync = mutation.mutateAsync;

  mutation.mutate = (variables, options) => {
    shouldShowDefaultError = options?.onError === undefined;
    return originalMutate(variables, options);
  };

  mutation.mutateAsync = async (variables, options) => {
    shouldShowDefaultError = options?.onError === undefined;
    return originalMutateAsync(variables, options);
  };

  return mutation;
}
