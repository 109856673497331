import { useNotification } from "@kyvg/vue3-notification";
import type { TranslateResult } from "vue-i18n";

import { isAxiosError } from "@/api/http-client";
import type { IOAuthErrorResponse } from "@/api/types/general-types";
import { snackbarMessages } from "@/modules/snackbar/snackbar-config";
import type { INotificationOptions } from "@/modules/snackbar/snackbar-types";
import {
  getDefaultNotificationOptions,
  toNotificationOptions,
} from "@/modules/snackbar/snackbar-utils";
import { i18n } from "@/plugins/i18n-plugin";
import { isAPIError } from "@/utils/error-utils";

export interface IUseNotificationsReturn {
  show(text: string, options: INotificationOptions): void;

  showInfo(message?: string | TranslateResult): void;

  showError(message?: string | TranslateResult): void;

  showSuccess(message?: string | TranslateResult): void;

  showErrorResponse(
    response: unknown,
    fallback?: string | TranslateResult,
    time?: number
  ): void;
}

export function useNotifications(): IUseNotificationsReturn {
  const { notify } = useNotification();

  function show(text: string, options: INotificationOptions) {
    notify({
      ...toNotificationOptions(options.type || "success", options),
      text,
    });
  }

  function showSuccess(message?: string | TranslateResult): void {
    show(
      (message as string) || snackbarMessages.success,
      getDefaultNotificationOptions("success")
    );
  }

  function showInfo(message?: string | TranslateResult): void {
    show(message as string, getDefaultNotificationOptions("info"));
  }

  function showError(message?: string | TranslateResult) {
    show(
      (message as string) || snackbarMessages.error,
      getDefaultNotificationOptions("error")
    );
  }

  function showErrorResponse(
    error?: unknown,
    fallback?: string | TranslateResult
  ) {
    // If the snackbar was already shown by the interceptor, don't show it again
    /*
    if (
      error &&
      (error as unknown as { _snackbarShown: boolean })._snackbarShown
    ) {
      return;
    }
    */

    if (error && isAPIError(error) && error.response) {
      let messages: (string | null)[] = [];

      messages = error.response.data.error.details.map((message) => {
        const translated = i18n.global.t(message.code, {
          field: message.field,
        }) as string;

        // fallback to generic error if not all messages can be translated
        if (translated === message.code) {
          console.warn("No translation found for:", message.code);
          return null;
        }

        return translated;
      });

      if (messages.length > 0 && !messages.some((message) => message == null)) {
        showError(messages.join("\n"));
      } else if (error.response?.data?.error?.code === "internal.stripe") {
        showError(error.response?.data?.error?.message || fallback);
      } else {
        showError(fallback);
      }
    } else if (
      isAxiosError(error) &&
      (error.response?.data as IOAuthErrorResponse)?.error?.code ===
        "internal.stripe"
    ) {
      const message = (error.response?.data as IOAuthErrorResponse)?.error
        .message;
      showError(message || fallback);
    } else {
      showError(fallback);
    }
  }

  return {
    show,
    showError,
    showErrorResponse,
    showSuccess,
    showInfo,
  };
}
