import { useQuery } from "@tanstack/vue-query";

import {
  getTranslations,
  refreshTranslations,
} from "@/api/services/translation-service";
import { useCustomMutation } from "@/api/use-custom-mutation";
import { useTranslate } from "@/composables/use-translate";

export function useTranslationsQuery() {
  const { locale } = useTranslate();
  return useQuery({
    queryKey: ["translations", locale],
    queryFn: () => getTranslations(),
  });
}

export function useTranslationsRefreshMutation() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useCustomMutation<Record<string, any>, void>({
    mutationFn: () => refreshTranslations(),
  });
}
