<template>
  <UITooltip :disabled="!$slots.tooltip" :position="tooltipPosition">
    <template #activator="{ props: activatorProps }">
      <v-btn
        :disabled="disabled"
        :color="variant"
        :loading="loading"
        variant="text"
        :active="active"
        :class="classes"
        :type="type"
        :data-test-id="dataTestId"
        size="36"
        :to="to"
        icon
        v-bind="{ ...activatorProps, ...$attrs }"
      >
        <slot name="icon">
          <v-icon v-if="icon" size="small">{{ icon }}</v-icon>
        </slot>

        <slot />
      </v-btn>
    </template>
    <template #default>
      <slot name="tooltip" />
    </template>
  </UITooltip>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import type { RouteLocationRaw } from "vue-router";

import type { ITooltipPosition } from "@/modules/ui/tooltip/tooltip.types.ts";
import UITooltip from "@/modules/ui/tooltip/UITooltip.vue";

interface Props {
  icon?: string;
  disabled?: boolean;
  loading?: boolean;
  active?: boolean;
  type?: "submit" | "reset" | "button";
  variant?: "primary" | "secondary" | "black" | "danger";
  iconSize?: "md" | "lg" | "xl";
  depressed?: boolean;
  tooltipPosition?: ITooltipPosition;
  dataTestId?: string;
  to?: RouteLocationRaw;
}

const props = withDefaults(defineProps<Props>(), {
  icon: undefined,
  loading: false,
  disabled: false,
  depressed: false,
  variant: "primary",
  type: "button",
  iconSize: "md",
  tooltipPosition: undefined,
  dataTestId: undefined,
  to: undefined,
});

const classes = computed(() => {
  const classes: string[] = [];

  if (props.variant === "secondary") {
    classes.push("text-primary");
  }

  if (props.variant === "danger") {
    classes.push("text-error");
  }

  if (props.active) {
    classes.push("secondary");
  }

  if (props.iconSize === "lg") {
    classes.push("text-lg");
  }

  if (props.iconSize === "xl") {
    classes.push("text-xl");
  }

  return classes;
});
</script>

<style scoped lang="scss">
.icon-lg {
  :deep(.v-icon) {
    font-size: 1.5em !important;
  }
}
</style>
