<template>
  <div
    :data-test-id="`base-snackbar base-snackbar-${variant}`"
    class="mt-4 overflow-hidden bg-white text-sm shadow-xl"
    :class="{
      'text-app-success': variant === 'success',
      'text-app-danger': variant === 'error',
      'text-app-primary': variant === 'info',
    }"
    @click="emit('click:close')"
  >
    <div role="status" aria-live="polite" class="p-4">
      <div class="flex w-full min-w-0 items-center gap-2 py-1">
        <div
          class="mr-2 text-xl"
          :class="{
            'text-app-danger': variant === 'error',
            'text-app-success': variant === 'success',
            'text-app-primary': variant === 'info',
          }"
        >
          <SuccessIcon v-if="variant === 'success'" />
          <InfoIcon v-if="variant === 'info'" />
          <ErrorIcon v-if="variant === 'error'" />
        </div>

        <div class="inline-block flex-1 text-wrap font-medium text-black">
          <slot />
        </div>

        <UIIconButton
          v-if="showClose"
          icon-size="xl"
          variant="black"
          icon="mdi-close"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import UIIconButton from "@/components/ui/UIIconButton.vue";
import type { INotificationType } from "@/modules/snackbar/snackbar-types";
import ErrorIcon from "~icons/material-symbols/cancel-rounded";
import SuccessIcon from "~icons/material-symbols/check-circle-rounded";
import InfoIcon from "~icons/material-symbols/info-rounded";

interface Props {
  variant?: INotificationType;
  showClose?: boolean;
}

withDefaults(defineProps<Props>(), {
  variant: "success",
  showClose: false,
});

const emit = defineEmits<{ "click:close": [] }>();
</script>

<style scoped lang="scss"></style>
