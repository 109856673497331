// src/mocks/browser.ts
import { setupWorker } from "msw/browser";

import { handlers } from "./handlers";

// This configures a Service Worker with the given request handlers.
// Export the worker instance so we can access its event emitter elsewhere.
export const worker = setupWorker(...handlers);

export async function enableMocking() {
  if (typeof window === "undefined") {
    return;
  }

  if (import.meta.env.VITE_ENABLE_MSW === "true" || import.meta.env.DEV) {
    await worker.start({
      onUnhandledRequest: "bypass",
      serviceWorker: {
        url: "/mockServiceWorker.js",
      },
    });
    return true;
  }
  return false;
}
